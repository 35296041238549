import * as React from "react";
import "./style.scss";

const PrepStatusTable: React.FunctionComponent = ({}) => {
  return (
    <div className="prep-status">
      <table>
        <thead>
          <tr className="grey">
            <th width="37%">PrEP status </th>
            <th>
              <span className="no-wrap"># of HIV infections /</span>
              Total # of individuals
            </th>
            <th>
              <span className="no-wrap">Total follow-up,</span> person-years
            </th>
            <th>
              <span className="no-wrap">Incidence (95% CI),</span> per 100
              person-years
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Overall<sup>a</sup>
            </td>
            <td>91/13,861</td>
            <td>26,210</td>
            <td>0.35 (0.28-0.43)</td>
          </tr>
          <tr>
            <td>Linked but not prescribed PrEP </td>
            <td>36/3,013</td>
            <td>4,119</td>
            <td>0.87 (0.63-1.21)</td>
          </tr>
          <tr className="red">
            <td>Prescribed PrEP but did not initiate </td>
            <td>13/811</td>
            <td> 1,226</td>
            <td>1.06 (0.62-1.83)</td>
          </tr>
          <tr>
            <td>Discontinued but reinitiated PrEP</td>
            <td>4/1,082</td>
            <td>1,420</td>
            <td>0.28 (0.11-0.75)</td>
          </tr>
          <tr className="red">
            <td>Discontinued and did not reinitiate PrEP </td>
            <td>38/2,108</td>
            <td>2,973</td>
            <td>1.28 (0.93-1.76)</td>
          </tr>
          <tr>
            <td>
              Persistent on PrEP<sup>b</sup>
            </td>
            <td>0/5,367</td>
            <td>9,139</td>
            <td>
              0.00 (0.00-0.04)<sup>c</sup>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default PrepStatusTable;
